import { useEffect, useState } from "react";
import { FaEye, FaDownload } from "react-icons/fa";

const Receipts = () => {
  const [recibos, setRecibos] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchReceipts = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/forms/receipts`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
      const data = await res.json();
      setRecibos(data);
    } catch (error) {
      console.error("❌ Error al cargar recibos:", error);
    } finally {
      setLoading(false);
    }
  };

  const downloadPDF = async (uuid) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/forms/download_receipt?receipt_uuid=${uuid}`
      );

      if (!response.ok) throw new Error("No se pudo descargar el recibo");

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `recibo_${uuid}.pdf`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("❌ Error al descargar PDF:", error);
      alert("No se pudo descargar el recibo");
    }
  };

  useEffect(() => {
    fetchReceipts();
  }, []);

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-primary">
          Historial de <span className="text-secondary">Recibos</span>
        </h1>
      </div>

      {loading ? (
        <p className="text-center text-gray-400">Cargando recibos...</p>
      ) : !recibos || recibos.length === 0 ? (
        <p className="text-center text-gray-500">
          No hay recibos disponibles aún
        </p>
      ) : (
        <div className="bg-white rounded-lg shadow overflow-hidden">
          <table className="w-full text-left border-separate border-spacing-0">
            <thead>
              <tr>
                <th className="p-4 text-sm font-medium text-gray-500 w-3/12">
                  Fecha
                </th>
                <th className="p-4 text-sm font-medium text-gray-500 w-3/12">
                  Monto
                </th>
                <th className="p-4 text-sm font-medium text-gray-500 w-2/12">
                  Estado
                </th>
                <th className="p-4 text-sm font-medium text-gray-500 text-center w-2/12">
                  Acciones
                </th>
              </tr>
            </thead>
            <tbody>
              {recibos.map((recibo) => (
                <tr key={recibo.uuid} className="border-b">
                  <td className="p-4 text-sm text-gray-500">
                    {recibo.fecha_pago}
                  </td>
                  <td className="p-4 text-sm text-gray-500">
                    ${recibo.total_depositado.toFixed(2)}
                  </td>
                  <td className="p-4 text-sm">
                    <span
                      className={`px-2 py-1 rounded-full text-xs font-semibold ${
                        recibo.status === "Pagado"
                          ? "bg-green-100 text-green-800"
                          : "bg-yellow-100 text-yellow-800"
                      }`}
                    >
                      {recibo.status}
                    </span>
                  </td>
                  <td className="p-4 flex items-center justify-center space-x-4">
                    <button
                      className="text-gray-500 hover:text-primary"
                      onClick={() => downloadPDF(recibo.uuid)}
                    >
                      <FaDownload size={20} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Receipts;
